import React, { useState, useEffect } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { useLocation } from '@reach/router'
import { Collapse, Navbar, NavbarBrand, Nav } from '../../../../common/lib'
import HeaderSearch from '../../../../common/components/Search'
import styles from './FirstMenu.module.scss'
import { MegaMenu } from '../../../../common/components'
import ThemeToggleComponent from '../ThemeToggler/ThemeToggler'

const FirstMenu = ({ location, scrollableLayout }) => {
  const path = location.pathname
  const [open, updateOpen] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(true)
  const locations = useLocation()
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 0
  );

  const updateWindowWidth = () => {
    setWindowWidth(typeof window !== 'undefined' ? window.innerWidth : 0);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateWindowWidth);

      return () => {
        window.removeEventListener('resize', updateWindowWidth);
      };
    }
  }, []);

  useEffect(() => {
    setIsMenuOpen(true)
  }, [locations])

  const handleMenuCollapse = () => {
    setIsMenuOpen(false)
    updateOpen(false)
  }

  const data = useStaticQuery(graphql`
  query allFirstMenus {
    megamenu: allDatoCmsMegaMenu(filter: {title: {eq: "Psychometric-Success.com"}}) {
      nodes {
        title
        treeChildren {
          title
          link
          position
          treeChildren {
            title
            link
            position
            treeChildren {
              title
              link
              position
            }
          }
        }
      }
    }
  }
  `)

  return (
    <>
      <Navbar
        id="header"
        color="light"
        light
        expand="md"
        container="xxl"
        // Conditional styling below - for the articles pages only
        // style={{ position: scrollableLayout ? 'relative' : 'sticky' }}
        className={`${scrollableLayout ? 'position-relative' : 'position-sticky'
          } ${styles.navbarColor} ${styles.responsiveNavbar}`}
      >
        <NavbarBrand
          tag={Link}
          to={path && path.match('trading') ? '/trading/forex' : '/'}
          {...{
            className: styles.navbarBrand,
          }}
        >
          Psychometric Success
        </NavbarBrand>

        {
          windowWidth <= 769 ? (
            <div className={`${styles.navButton}`}>
              {/* <ThemeToggleComponent /> */}
              <button
                onClick={() => updateOpen(!open)}
                aria-label="Toggle navigation"
                type="button"
                className={`FirstMenu-module--toggleNav--2Tcqh navbar-toggler ${styles.hamburger}`}
                
              >
                <span
                  className={`navbar-toggler-icon ${styles.navbarTogglerIconSvg}`}
                />
              </button>
            </div>
          ) : ""
        } 

        <Collapse isOpen={open} navbar className={styles.navCollapse}>
          <Nav className={styles.nestedNacCollapse} navbar>
            {data?.megamenu?.nodes?.map((menuItem) => (
              <MegaMenu
                menuData={menuItem}
                isMenuOpen={isMenuOpen}
                handleMenuCollapse={handleMenuCollapse}
              />
            ))}
            {/* {
              windowWidth > 770 ? (
                <ThemeToggleComponent />
              ) : ""
            } */}
            <HeaderSearch location={location} />
          </Nav>
        </Collapse>
        
      </Navbar>
      {/* {showpopup && <MainSitePopup ispopupopen={handlePopup} showPopup />} */}
    </>
  )
}

export default FirstMenu
