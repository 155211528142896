import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Row, Col } from '../../../../common/lib'
import SocialMediaLinks from './SocialMediaLinks'
import styles from './Footer.module.scss'
import SubscribeToNewsletter from '../SubscribeToNewsletter'

const Footer = () => {
  const currentYear = new Date().getFullYear()

  const data = useStaticQuery(graphql`
    query footerQuery {
      footerLinks: allDatoCmsPsFooterGrid {
        edges {
          node {
            id
            title
            column {
              id
              label
              url
            }
          }
        }
      }
    }
  `)
  return (
    <footer className={styles.footer}>
      <>
        <Row className={`${styles.navLinks} pt-4 pb-5 container`}>
          {data.footerLinks.edges.map(renderColumn)}
        </Row>

        {/* Should be shown after implementation of functionality of external form */}
        {/* <SubscribeToNewsletter /> */}

        <Row className="container">
          <Col md={3} lg={3} className="px-0">
            <div className={`${styles.footer_logo} pe-3`}>
              Psychometric Success
            </div>
          </Col>
          <Col md={4} lg={4} className={styles.socialMediaLinks}>
            {/* <div> */}
            <SocialMediaLinks />
            {/* </div> */}
          </Col>
          <Col md={5} lg={5} className="px-0">
            <p className={styles.copyright}>
              © Psychometric Success 2007-{currentYear}. All rights reserved.
            </p>
          </Col>
        </Row>
      </>
    </footer>
  )
}

const renderColumn = ({ node: { id, title, column } }) => (
  <Col lg={6} md={6} key={id} className={`${styles.column} px-0`}>
    <div className={styles.columnHeading}>{title}</div>
    {column.map(renderLink)}
  </Col>
)

const renderLink = ({ id, label, url }) => (
  <div key={id}>
    <a href={url}>{label}</a>
  </div>
)

export default Footer
