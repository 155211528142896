import React, { useState } from 'react'
import Footer from '../Footer'
import FirstMenu from '../FirstMenu'
import SecondMenu from '../SecondMenu_remove'
import { PageHelmet } from '../../../../common/components'
import "../../../static/layout.css"
import 'bootstrap/dist/css/bootstrap.min.css'

export default function Layout({
  children,
  location,
  pageContext: { layout },
}) {

  let { scrollableLayout, exclude } = false
  if (layout) {
    scrollableLayout = layout.scrollableLayout
    if (layout.type && layout.type.exclude) {
      exclude = true
    }
  }
  return exclude ? (
    children
  ) : (
    <>
      <PageHelmet />
      {/* <div 
        style={{
          backgroundColor: 'var(--bg)',
          color: 'var(--textNormal)',
          transition: 'color 0.2s ease-out, background 0.2s ease-out',
        }}> */}
        <FirstMenu location={location} scrollableLayout={scrollableLayout} />
        <div
          className="secondMenuWrap"
          // Conditional styling below - for the articles pages only
          style={{
            position: scrollableLayout && 'relative',
            top: scrollableLayout && '0',
          }}
        >
          {/* SecondMenu and HelloBar is available only for aptitude tests and articles with
        'aptitude-test' in the URL */}
          {location.pathname.match('aptitude-test') && (
            <>
              <SecondMenu location={location} />
            </>
          )}
        </div>
        {children}
        <Footer />
      {/* </div> */}
    </>
  )
}
