import React, { useState, useContext, useEffect } from 'react'
import classNames from 'classnames'
import styles from './ResumeWidget.module.scss'
import GeoContext from '../../utils/geoContext'

const ResumeWidget = ({ slug, ps = false }) => {
  const [showCV, setShowCV] = useState(null)
  const countryData = useContext(GeoContext)

  useEffect(() => {
    const gulfCountryCodes = [
      { code: 'BH', country: 'Bahrain' },
      { code: 'IQ', country: 'Iraq' },
      { code: 'KW', country: 'Kuwait' },
      { code: 'OM', country: 'Oman' },
      { code: 'QA', country: 'Qatar' },
      { code: 'SA', country: 'Saudi Arabia' },
      { code: 'AE', country: 'United Arab Emirates' },
    ]

    const cvCountries = [
      { code: 'GB', country: 'United Kingdom' },
      { code: 'IR', country: 'Ireland' },
      ...gulfCountryCodes,
    ]

    if (countryData) {
      setShowCV(
        cvCountries
          .map((country) => country.code)
          .includes(countryData?.geo?.country_code),
      )
    } else {
      // fetch fails
      setShowCV(null)
    }
  }, [countryData])

  const label = showCV === null ? 'CV/Resume' : (showCV && 'CV') || 'Resume'

  return (
    <div className={classNames(styles.sidebar, 'shadow-sm w-100')}>
      <div className={styles.sidebarHeader}>Want to Upgrade Your {label} ?</div>

      <div className={styles.form}>
        <p>Choose PurpleCV and get:</p>
        <ul>
          <li> Access to your own specialist writer</li>
          <li>Unlimited revisions for 12 months</li>
          <li>Average 2-day turnaround (specialist CV 5 days)</li>
          <li>No templates are used on any of our CVs</li>
        </ul>

        <input type="hidden" name="form-name" value="cvreview" />
        <input
          type="hidden"
          name="country"
          value={countryData?.geo?.country_code}
        />

        <input type="hidden" name="articleSlug" value={slug} />
        <input type="hidden" name="city" value={countryData?.geo?.city} />

        <a
          className="btn btn-orange w-100"
          href={
            ps === true
              ? 'https://purplecv.co.uk/cv-writing?wpam_id=416&referrer-page=rightsidebox-ps'
              : 'https://purplecv.co.uk/cv-writing?wpam_id=416&referrer-page=rightsidebox'
          }
          rel="sponsored nofollow"
          target="blank"
        >
          Get Your CV Now
        </a>
        <p className={styles.sponsoredText}>
          <small>Sponsored</small>
        </p>
      </div>
    </div>
  )
}

export default ResumeWidget
